//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  computed: {
    fontSize() {
      return this.$vuetify.breakpoint.smAndDown ? '15vw' : '10vw';
    },
  },
};
